
import axios from 'axios';
export default {
    
    async createSalaryReport(params)  {
        return await axios.post(`salary_report/create` , params)
    },
    async createSalaryReportList(params)  {
        return await axios.post(`salary_report/create/list` , params)
    },
    async updateSalaryReportColumn(column , value , data)  {
        return await axios.put(`salary_report/update_list?${column}=${value}` , data)
    },
    async deleteSalaryReportList(list)  {
        return await axios.delete(`salary_report/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportSalaryReport(column , value)  {
        return await axios.get(`salary_report/report?${column}=${value}`)
    },
    async getAllSalaryReport()  {
        return await axios.get(`salary_report/all`)
    },
    async getOneSalaryReport(salary_report_id)  {
        return await axios.get(`salary_report/all/${salary_report_id}`)
    },
    async getSalaryReportByColumn(column , value)  {
        return await axios.get(`salary_report/filter?column=${column}&value=${value}`)
    },
    async deleteSalaryReport(salary_report_id)  {
        return await axios.delete(`salary_report/delete/${salary_report_id}`)
    },
    async updateSalaryReport(salary_report_id , params)  {
        return await axios.put(`salary_report/update/${salary_report_id}` , params)
    }
}